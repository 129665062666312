<template>
  <div id="order-modal-wrapper">
    <!-- Modal -->
    <div id="calendarModal" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" v-if="selectedOrder">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Selected Order</h4>
          </div>
          <div class="modal-body">
            <table class="table small">
              <tbody>
                <tr>
                  <th scope="row">Order Number</th>
                  <td>{{ selectedOrder.order_number }}</td>
                </tr>
                <tr>
                  <th scope="row">Order State</th>
                  <td>{{ selectedOrder.order_state.name }}</td>
                </tr>
                <tr>
                  <th scope="row">Client</th>
                  <td>{{ selectedOrder.client.company_name }}</td>
                </tr>
                <tr>
                  <th scope="row">Service Date and Time</th>
                  <td>{{ helpers.formatDateTime(selectedOrder.service_date_time) }}</td>
                </tr>
                <tr>
                  <th scope="row">Assigned Worker</th>
                  <td>{{ selectedOrder.assigned_worker.first_name }} {{ selectedOrder.assigned_worker.last_name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-info" data-dismiss="modal" v-on:click="viewOrder()">View Order</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import * as helpers from '../../../../helpers/functions.js';

export default {
  name: 'CalendarModal',
  props: ['selectedOrder'],
  data() {
    return {
      helpers
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      ordersMadeSelected: 'ordersMadeSelected',
      isDashboardAdmin: 'isDashboardAdmin'
    })
  },
  methods: {
    ...mapActions({
      selectOrderMade: 'selectOrderMade',
      getorderResults: 'getorderResults'
    }),
    ...mapMutations({
      getOrdersMade: 'getOrdersMade',
      selectOrderFromCalendar: 'selectOrderFromCalendar'
    }),
    viewOrder() {
      this.$router.push('/orders/' + this.selectedOrder.order_number);
    }
  },
  created: function() {},
  updated: function() {},
  mounted: function() {}
};
</script>

<style lang="scss">
.cart-wrapper {
  &:hover {
    cursor: pointer;
  }
}
</style>
