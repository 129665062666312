<template>
  <div id="order-calendar-main-page">
    <navigation></navigation>
    <div class="dashboard-container-right" v-if="created">
      <h2 class="title mt-4">Calendar</h2>
      <hr />
      <transition name="scale">
        <div class="calendar-wrapper">
          <full-calendar ref="calendar" :event-sources="eventSources" :config="config" />
          <div class="calendar-loader" v-if="loadingEvents"><div class="loader-medium"></div></div>
        </div>
      </transition>
      <hr />
      <div class="clearfix"></div>
      <div v-if="isDashboardAdmin" class="calendar-checkbox">
        <MaterialCheckbox @checkboxChanged="showCurrentUserOrdersOnly" class="material-checkbox" :selected="currentUserOrdersOnly"></MaterialCheckbox>
        <span class="text label-checkbox">My assigned orders only</span>
      </div>
    </div>
    <CalendarModal :selectedOrder="selectedOrderCalendar" />
  </div>
</template>

<script>
import Navigation from '../../generic/Navigation.vue';
import CalendarModal from './calendar-modal/CalendarModal.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
//import jQuery from 'jquery';
import { FullCalendar } from 'vue-full-calendar';
import moment from 'moment';
import MaterialCheckbox from '../../generic/MaterialCheckbox.vue';

import * as helpers from '../../../helpers/functions.js';

import 'fullcalendar/dist/fullcalendar.css';

let _this;

export default {
  name: 'OrdersCalendar',
  data() {
    return {
      created: false,
      loadingEvents: false,
      selectedOrderCalendar: null,
      calendarUser: '',
      currentUserOrdersOnly: false,
      eventSources: [
        {
          events(start, end, timezone, callback) {
            _this.loadingEvents = true;
            _this.$store.dispatch('getOrdersCalendar', { start: start.unix(), end: end.unix(), user: _this.calendarUser }).then(doc => {
              var events = [];
              _this.getOrdersMade(doc);
              _this.loadingEvents = false;
              jQuery(doc).each(function() {
                events.push({
                  title: this.assigned_worker.first_name + ' ' + this.assigned_worker.last_name,
                  start: this.service_date_time,
                  id: this.id
                });
              });
              callback(events);
            });
          }
        }
      ],
      config: {
        defaultView: 'month',
        themeSystem: 'bootstrap3',
        displayEventTime: false,
        eventClick: function(event) {
          let clickedOrder = _this.ordersMade.filter(e => e.id == event.id);
          _this.selectedOrderCalendar = clickedOrder[0];
          jQuery('#calendarModal')
            .appendTo('body')
            .modal('show');
        },
        eventRender: function(event, element) {
          element.find('.fc-title').append('<br/>' + event.start.format('hh:mm A'));
        }
      }
    };
  },
  components: { Navigation, CalendarModal, FullCalendar, MaterialCheckbox },
  computed: {
    ...mapGetters({
      ordersMade: 'ordersMade',
      userData: 'userData',
      isDashboardAdmin: 'isDashboardAdmin'
    })
  },
  methods: {
    ...mapActions({}),
    ...mapMutations({
      getOrdersMade: 'getOrdersMade'
    }),
    showCurrentUserOrdersOnly() {
      this.currentUserOrdersOnly = !this.currentUserOrdersOnly;
      if (this.currentUserOrdersOnly) this.calendarUser = this.userData.id;
      else this.calendarUser = '';
      this.refreshEvents();
    },
    refreshEvents() {
      this.$refs.calendar.$emit('refetch-events');
    }
  },
  created: function() {
    //jQuery('body').hide();
    _this = this;
  },
  updated: function() {},
  mounted: function() {
    this.created = true;
  },
  destroyed: function() {
    //clearInterval(this.getDataInterval);
    helpers.removeModalFromBody('calendarModal');
  }
};
</script>

<style lang="scss">
#calendar {
  .fc-right {
    opacity: 0;
    pointer-events: none;
  }
}
</style>
